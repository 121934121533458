.c-footer {
  margin: 10rem 0 0;
  padding: 3rem 0 3rem 20rem;
  background-color: #000;
  position: relative;
  z-index: 1;
  text-align: center;
}

.c-footer small {
  color: #fff;
}

@media screen and (max-width: 769px) {
  .c-footer {
    padding: 3rem 0 3rem;
  }

  .c-footer small {
    font-size: 3vw;
  }
}
