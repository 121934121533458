.c-alert__wrap {
  width: 76%;
  position: fixed;
  top: 4rem;
  left: 20.5%;
  z-index: 5;
}

.c-alert {
  padding: 2rem 3rem;
  position: relative;
}

.c-alert__crosse {
  padding: 25px 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: transparent;
}

.c-alert__crosse span {
  display: block;
  position: relative;
  width: 3rem;
  height: 3rem;
}

.c-alert__crosse::before,
.c-alert__crosse::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px; /* 棒の幅（太さ） */
  height: 30px; /* 棒の高さ */
  background: #fff;
}

.c-alert__crosse::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.c-alert__crosse::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.c-alert__wrap.c-success {
  background-color: #2d8ff8;
  color: #fff;
}
