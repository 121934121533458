.work-title {
  margin: 4rem 0 10rem;
  font-size: 2.8rem;
  text-align: center;
  color: #fff;
}

.work-detail {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.work-progress-circle {
  display: flex;
  justify-content: center;
}

.work-progress {
  position: fixed;
  z-index: 11;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(30%);
  color: #fff;
}

.work-mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  z-index: 10;
}

@media screen and (max-width: 769px) {
  .work-progress {
    transform: translateY(-50%) translateX(-50%);
  }
}

@media screen and (max-width: 576px) {
  .work-title {
    font-size: 6vw;
  }
}
