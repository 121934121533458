.c-homeWork-card {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
}

.c-homeWork-card .c-card {
  width: calc(50% - 2rem);
}

.c-homeWork-progress {
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 576px) {
  .c-homeWork-card .c-card {
    width: 100%;
  }
}
