/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Noto Sans JP", "Helvetica Neue", "Helvetica", "Hiragino Sans",
    "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 1.6rem;
  overflow-x: hidden;
}
@media screen and (max-width: 575.98px) {
  body {
    font-size: 3.8647342995vw;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul {
  list-style: none;
}

ol {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #000;
}
a:focus {
  text-decoration: none;
}

@media (min-width: 751px) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}
img {
  width: 100%;
  vertical-align: bottom;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 0.1rem dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 0.1rem;
  border: 0;
  border-top: 0.1rem solid #cccccc;
  margin: 0.1rem 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

button {
  border-color: transparent;
}

input[type="text"],
input[type="email"],
input[type="submit"],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}

address {
  font-style: normal;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="number"],
select {
  width: 100%;
  padding: 1.5rem 1.5rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  border: 1px solid #ddd;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 0.3rem;
  height: 20rem;
  border: 0.1rem solid #ddd;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1rem;
}

img[src$=".svg"] {
  fill: currentColor;
  width: auto;
  height: auto;
}

strong {
  font-weight: 500;
}

.area-400 {
  font-family: "area-normal", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.area-700 {
  font-family: "area-extended", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.homeSection {
  margin: 13rem 0;
}

@media screen and (max-width: 576px) {
  .homeSection {
    margin: 10rem 0;
  }
}
