.c-homeCatch-warp {
  text-align: center;
}

.c-homeCatch-logo-img {
  margin: 0 auto;
  padding: 0 0 0 2rem;
  width: 13.5rem;
}

.c-homeCatch-logo-img img {
  width: 100%;
}

.c-homeCatch-logo-text {
  margin: 2rem 0 0;
  color: #fff;
}

.c-homeCatch-heading span {
  margin: 4rem 0 0;
  text-align: center;
  text-align: left;
  display: inline-block;
  font-size: 4.8rem;
  color: #fff;
  line-height: 1.4;
}

@media screen and (max-width: 576px) {
  .c-homeCatch-heading span {
    font-size: 11vw;
  }
}
