.c-sidebar {
  background-color: #fff;
  width: 17%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  filter: drop-shadow(0 3rem 2rem rgba(0, 0, 0, 0.3));
  transition: 0.4s;
}

.c-sidebar::before {
  content: "";
  width: 5rem;
  height: 0.1rem;
  display: block;
  background-color: #000;
  position: absolute;
  top: 4rem;
}

.c-sidebar::after {
  content: "";
  width: 5rem;
  height: 0.1rem;
  display: block;
  background-color: #000;
  position: absolute;
  bottom: 4rem;
}

.c-sidebar-wrap {
  position: relative;
}

.c-sidebar header {
  position: relative;
}

.c-sidebar-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-sidebar-list-item {
  margin: 2rem 0 0;
  cursor: pointer;
}

.c-sidebar-login {
  position: absolute;
  bottom: 7rem;
}

.c-sidebar-hamburger {
  display: none;
}

@media screen and (max-width: 769px) {
  .c-sidebar {
    margin: 0 0 0 -20rem;
    width: 20rem;
    z-index: 10;
  }

  .c-sidebar.active {
    margin: 0;
  }

  .c-sidebar-hamburger {
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    display: block;
    top: 4rem;
    left: 4rem;
    z-index: 12;
    transition: 0.4s;
  }

  .c-sidebar-hamburger.active {
    left: 24rem;
  }

  .c-sidebar-hamburger-trigger,
  .c-sidebar-hamburger-trigger span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
  }

  .c-sidebar-hamburger-trigger {
    position: relative;
    width: 6vw;
    height: 5vw;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
  }

  .c-sidebar-hamburger-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
  }

  .c-sidebar-hamburger-trigger span:nth-of-type(1) {
    top: 0;
  }

  .c-sidebar-hamburger-trigger span:nth-of-type(2) {
    top: 2.3vw;
  }

  .c-sidebar-hamburger-trigger span:nth-of-type(3) {
    bottom: 0;
  }

  .c-sidebar-hamburger-trigger.active span:nth-of-type(1) {
    transform: translateY(17px) rotate(-45deg);
  }

  .c-sidebar-hamburger-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }

  .c-sidebar-hamburger-trigger.active span:nth-of-type(3) {
    transform: translateY(-18px) rotate(45deg);
  }
}

@media screen and (max-width: 576px) {
  .c-sidebar-hamburger {
    padding: 1.3rem;
    top: 2rem;
    left: 2rem;
  }

  .c-sidebar-hamburger-trigger {
    width: 7vw;
    height: 6vw;
  }

  .c-sidebar-hamburger-trigger span:nth-of-type(2) {
    top: 2.4vw;
  }

  .c-sidebar-hamburger-trigger.active span:nth-of-type(1) {
    transform: translateY(8px) rotate(-45deg);
  }

  .c-sidebar-hamburger-trigger.active span:nth-of-type(3) {
    transform: translateY(-13px) rotate(45deg);
  }
}
