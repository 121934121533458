.c-form-button {
  padding: 3rem 0;
  width: 100%;
  background-color: #ebbc50;
  border-radius: 10rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 700;
  position: relative;
  cursor: pointer;
  transition: 0.6s;
  display: block;
  text-align: center;
}

.c-form-button:hover {
  opacity: 0.6;
}

.c-form-button::after {
  margin: 0;
  content: "";
  background-image: url(../../../public/icon/arrow-right.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
}

@media screen and (max-width: 576px) {
  .c-form-button {
    padding: 2rem 0;
    font-size: 4vw;
  }
}
