.c-subPage-title-en {
  margin: 0 0 1rem;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 1;
  text-align: center;
  color: #fff;
}

.c-subPage-title-sub {
  font-weight: 700;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 576px) {
  .c-subPage-title-en {
    font-size: 9vw;
  }

  .c-subPage-title-title {
    font-size: 6vw;
  }
}
